@layer components {
  .divider {
    margin: var(--brand-divider-margin);
    width: var(--brand-divider-width);
    height: var(--brand-divider-height);
  }

  .light {
    background-color: var(--brand-divider-background-light);
  }

  .dark {
    background-color: var(--brand-divider-background-dark);
  }
}
