.container {
  --heading-font-size: var(--font-size-4xl);
  --home-page-search-results-max-height: 40vh;
  --home-page-form-max-width: auto;
  --home-page-form-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--space-8x);
  padding: 0 var(--space-6x);

  @media (--screen-md) {
    --home-page-form-max-width: 500px;
    --home-page-form-width: 408px;
    margin-bottom: 84px;
  }
}

.heading {
  margin-bottom: var(--space-4-5x);
}

.searchComboBox {
  --search-combobox-button-border-radius: 1111px;
  --search-combobox-root-border-radius: 1111px;
  --search-combobox-root-padding: 0 0 0 var(--space-3x);
  --search-combobox-header-border-radius: var(--border-radius-full);
  --search-combobox-root-border-color: var(--color-gray-300);
  --search-combobox-root-border-style: solid;
  border-radius: var(--border-radius-full);
}
